html body {
 margin: 0;
 padding: 0;
 font-size: 14px;
 background-color: #000;
}

// .keyboard {
//     // @link-color: #428bca; // sea blue 
//     width: 100vw;
//     height: 100vh;
//     background-color: @bodybg;
//     position: relative;
//     .header {
//         width: 100vw;
//         height: 8vh;
//         background-color: @bodyheaderbg;
//         display: flex;
//         .logo {
//             display: inline-flex;
//             flex: 1;
//             padding: 1vh 2vw ;
//             box-sizing: border-box;
//             img {
//                 width: auto;
//                 height: 7vh;
//             }
//         }
//         .menu {
//             padding: 1vh 2vw ;
//             display: inline-flex;
//             flex: 1;
//             align-items: center;
//             justify-content: center;
//             line-height: 7vh;
//             font-size: 4vh;
//             .text {
//                 margin-left: 10px;
//                 font-size: 14px;
//             }
//             cursor: default;
//         }
//         .connect {
//             color: #fff;
//         }
//         .disconnect {
//             color: #666;
//         }
//         .setting {
//             padding: 0vh 3vw ;
//             display: inline-flex;
//             flex: 1;
//             .item {
//                 width: 100px;
//                 height: 30px;
//                 background-color: #fff;
//                 border-radius: 5px;
//                 text-align: center;
//                 line-height: 30px;
//                 margin-left: 16px;
//                 color: @bodyheaderbg;
//             }
//             align-items:center;
//             justify-content:flex-end;
//         }
//     }

//     ._slider {
//         height: 92vh;
//         // background-color: red;
//         padding: 4.5vh 1vh 3vh 1vh;
//         font-size: 14px;
//         position: relative;
//         box-sizing: border-box;
//         ._slider_header_text {
//             height: 30px;
//             // background-color: #FC5B6A;
//             font-size: 14px;
//             line-height: 30px;
//             padding-left: 3vh;
//             padding-right: 0;
//             // background-color: black;
//             display: inline-block;
//             cursor: pointer;
//         }
//         ._slider_header_svg{
//             height: 30px;
//             font-size: 20px;
//             text-align: right;
//             padding-right: 3vh;
//             color: @bodyheaderbg;
//             // background-color: black;
//             display: inline-block;
//             cursor: pointer;
//             float: right;
//         }
//         ._slider_body {
//             // width: 100%;
//             // background-color: black;
//             margin-top: 0;
//             display: block;
//         }
//     }
//     .item_btn {
//         width: 100%;
//         height: 42px;
//         background-color: rgba(0, 0, 0, 0);
//         padding-left: 3vh;
//         padding-right: 3vh;
//         line-height: 42px;
//         margin-top: 3px;
//         box-sizing: border-box;
//         color: #666;
//         .anticon {
//             float: right !important;
//             font-size: 18px;
//             padding-top: 13px;
//             box-sizing: border-box;
//         }
//     }
//     .item_btn:hover {
//         .item_btn();
//         color: @bodyheaderbg;
//     }
//     .checked {
//         background-color: @silderbtn;
//     }
//     .checked:hover {
//         background-color: @silderbtn;
//     }

//     .main-box {
//         width: 100vw;
//         height: 92vh;
//         .silder {
//             width: 248px;
//             // min-width: 208px;
//             // max-width: 248px;
//             background-color: @silderbg;
//             float: left;
//         }
//         .content {
//             width: auto;
//             height: 92vh;
//             margin-left: 248px;
//             padding: 4vh 3vw 4vh 3vw;
//             // background-color: aqua !important;
//             position: relative;
//             box-sizing: border-box;
//             overflow-x: hidden;
//             overflow-y: scroll;
//             .tab-btn {
//                 box-sizing: border-box;
//                 display: flex;
//                 margin-top: 20px;
//                 .ant-radio-group {
//                     width: 100%;
//                     // background-color: @silderbtn;
//                     display: flex;
//                     flex:1;
//                     .ant-radio-button-wrapper {
//                         height: 45px;
//                         line-height: 45px;
//                         background-color: @silderbg;
//                     }
//                     .ant-radio-button-wrapper:hover {
//                         color: @bodyheaderbg !important;
//                     }
//                     .ant-radio-button-checked {
//                         border: 1px @bodyheaderbg solid;
//                         color: @bodyheaderbg;
//                     }
//                     .ant-radio-button-checked:hover {
//                         color: @bodyheaderbg !important;
//                     }
//                     .ant-radio-button-wrapper-checked{
//                         border: 1px @bodyheaderbg solid;
//                         color: @bodyheaderbg;
//                     }
//                     .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
//                         background-color: @bodyheaderbg;
//                         opacity: 0;
//                     }
//                 }
                
//             }
//             .ant-tabs-nav-wrap {
//                 height: 0 !important;
//                 margin: 0 !important;
//                 padding: 0 !important;
//             }
//             .ant-tabs-nav-wrap::before {
//                 height: 0 !important;
//                 margin: 0 !important;
//                 padding: 0 !important;
//             }
//         }

//         .hong {
//             ._slider_hong {
//                 ._slider();
//                 height: 66vh;
//                 padding: 2vh 1vh 2vh 1vh; 
//             }
//             .hongOtion {
//                 height: 8vh;
//                 padding: 2vh 1vh 1vh 1vh; 
//                 box-sizing: border-box;

//                 button {
//                     // .ant-btn-default {

//                     // }
//                     color: @bodyheaderbg;
//                     border: 1px @silderbg solid;
//                 }
//                 button:hover {
//                     border: 1px @bodyheaderbg solid;
//                 }
//             }
//             .hongOtion-body {
//                 height: 58vh;
//                 padding: 2vh 1vh 2vh 1vh; 
//             }
//         }
//         .custom {
//             height: 72vh;
//             overflow: hidden;
//             box-sizing: border-box;
            
//             .keys {
//                 height: 35vh;
//                 margin-top: 3vh;
//                 .key-board {
//                     height: 35vh;
//                     overflow-y: scroll;
//                     box-sizing: content-box;
//                 }
//                 position: relative;
//                 :where(.css-dev-only-do-not-override-zg0ahe).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
//                     color: @bodyheaderbg;
//                 }
//                 .ant-tabs:hover {
//                     color: @silderbtn !important;
//                 }
//                 .ant-tabs-tab-btn:hover {
//                     color: @silderbtn !important;
//                 }
//                 .ant-tabs-tab-active:hover {
//                     color: @silderbtn !important;
//                 }
//                 .ant-tabs-ink-bar {
//                     background-color: @bodyheaderbg;
//                 }
//             }
//         }

//         .other {
//             height: 72vh;
//             overflow: hidden;
//             box-sizing: border-box;
//             // background-color: #666;
//             .other-body {
//                 height: 35vh;
//                 margin-top: 3vh;
//                 background-color: @silderbg;
//                 border-radius: 5px;
//                 .label {
//                     height: 32px;
//                     line-height: 32px;
//                     font-size: 14px;
//                     margin-right: 30px;
//                 }
//             }
//         }

//         .linesetting {
//             height: 72vh;
//             overflow: hidden;
//             box-sizing: border-box;
//             // background-color: #666;
//             .linesetting-body {
//                 height: 35vh;
//                 margin-top: 3vh;
//                 background-color: @silderbg;
//                 border-radius: 5px;
//                 padding: 2vh 3vh;
//                 .label {
//                     height: 32px;
//                     line-height: 32px;
//                     font-size: 14px;
//                     margin-right: 30px;
//                     color: #666;
//                 }
//             }
//         }
       
//     }
//     .input-Btn {
//         width: 60px;
//         height: 60px;
//         // text-align: center;
//         margin: 3px;
//         border-radius: 5px;
//         color: @bodyheaderbg;
//         background-color: @btnColor;
//         border: 1px @bodybg solid;
//     }
//     .input-Btn:hover {
//        .input-Btn();
//         color: @bodyheaderbg !important;
//         background-color: #fff !important;
//         border: 1px @bodyheaderbg solid !important;
//     }

//     .cell-Btn {
//         width: 30px;
//         height: 30px;
//         // text-align: center;
//         margin: 3px;
//         border-radius: 5px;
//         color: @bodyheaderbg;
//         background-color: @btnColor;
//         border: 1px @bodybg solid;
//         .ant-input-number-group-addon {
//             color:  @bodybg !important;
//         }
//     }
//     .cell-Btn:hover {
//         .cell-Btn();
//         color: @bodyheaderbg !important;
//         background-color: #fff !important;
//         border: 1px @bodyheaderbg solid !important;
//     }

//     .cell-input {
//         width: 120px;
//         height: 30px;
//         // text-align: center;
//         margin: 3px;
//         border-radius: 5px;
//         color: @bodyheaderbg;
//         background-color: @btnColor;
//         border: 1px @bodybg solid;
        
//     }
//     // .cell-input:hover {
//     //     width: 120px;
//     //     height: 30px;
//     //     // text-align: center;
//     //     margin: 3px;
//     //     border-radius: 5px;
//     //     color: @bodyheaderbg !important;
//     //     background-color: #fff !important;
//     //     border: 1px @bodyheaderbg solid !important;
//     // }

//     .ant-slider {
//         .ant-slider-track {
//             background-color: @bodyheaderbg;
//         }
//         .ant-slider-handle:focus::after {
//             box-shadow: 0 0 0 4px @bodyheaderbg !important;
//         }
//         .ant-slider-handle::after {
//             box-shadow: 0 0 0 2px @bodyheaderbg !important;
//         }
//     }

//     .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
//         background: @bodyheaderbg;
//     }
//     .ant-switch.ant-switch-checked:not(.ant-switch-disabled) {
//         background: @bodyheaderbg;
//     }
//     .ant-input-number-outlined:focus-within{
//         border: 1px @bodyheaderbg solid !important;
//     }
//     .ant-input-number-outlined{
//         border: 1px @btnColor solid !important;
//     }
//     .ant-input-number-group-wrapper-outlined .ant-input-number-group-addon {
//         background: rgba(0, 0, 0, 0);
//         border: 1px solid @btnColor;
//     }
//     .ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible) {
//         color: @bodyheaderbg !important;
//     }
//     .ant-tabs .ant-tabs-tab-btn:not(:focus-visible) {
//         color: @bodyheaderbg !important;
//     }
//     .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
//         color: @bodyheaderbg !important;
//     }

//     /* 滚动条样式 */
//     div::-webkit-scrollbar {
//         width: 8px;
//         height: 8px;
//     }
//     /* 滑块样式 */
//     div::-webkit-scrollbar-thumb {
//         background-color: @bodyheaderbg;
//         border-radius: 10px;
//     }
//     /* 滚动条轨道样式 */
//     div::-webkit-scrollbar-track {
//         background-color: #f2f2f2;
//         border-radius: 10px;
//     }

//     .board {
//         width: 100%;
//         height: 35vh;
//         overflow-y: scroll;
//         position: relative;
//         text-align: center;
//         overflow: hidden;
//         .key-board-top {
//             width: 100%;
//             height: 35vh;
//             display: block;
//         }
//         .key-board-body {
//             background-color: @borderbgcolor;
//             border-radius: 5px;
//         }
//     }
// }

.pink-theme {
    @bodyheaderbg: #FC5B6A;
    @bodybg: #F9F8F6;
    @silderbtn: #FFC8C8;
    @silderbg: #FBF1EF;
    @btnColor: #F9E9E9;
    @borderbgcolor:#F8F1EF;
    width: 100vw;
    height: 100vh;
    background-color: @bodybg;
    position: relative;
    .header {
        width: 100vw;
        height: 8vh;
        background-color: @bodyheaderbg;
        display: flex;
        .logo {
            display: inline-flex;
            flex: 1;
            padding: 2vh 2vw 2vh 0;
            box-sizing: border-box;
            img {
                width: auto;
                height: 4vh;
            }
        }
        .menu {
            padding: 1vh 2vw ;
            display: inline-flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            line-height: 7vh;
            font-size: 4vh;
            .text {
                margin-left: 10px;
                font-size: 14px;
            }
            cursor: default;
        }
        .connect {
            color: #fff;
        }
        .disconnect {
            color: #666;
        }
        .setting {
            padding: 0vh 3vw ;
            display: inline-flex;
            flex: 1;
            .item {
                width: 100px;
                height: 30px;
                background-color: #fff;
                border-radius: 5px;
                text-align: center;
                line-height: 30px;
                margin-left: 16px;
                color: @bodyheaderbg;
            }
            align-items:center;
            justify-content:flex-end;
        }
    }

    ._slider {
        height: 92vh;
        // background-color: red;
        padding: 4.5vh 1vh 3vh 1vh;
        font-size: 14px;
        position: relative;
        box-sizing: border-box;
        ._slider_header_text {
            width: 120px;
            height: 3vh;
            // background-color: #FC5B6A;
            font-size: 14px;
            line-height: 30px;
            padding-left: 3vh;
            padding-right: 0;
            // background-color: black;
            display: inline-block;
            cursor: pointer;
            overflow: hidden;
        }
        ._slider_header_svg{
            height: 3vh;
            font-size: 20px;
            text-align: right;
            padding-right: 3vh;
            color: @bodyheaderbg;
            // background-color: black;
            display: inline-block;
            cursor: pointer;
            position: absolute;
            float: right;
        }
        ._slider_body {
            height: 78vh;
            // width: 100%;
            // background-color: black;
            // margin-top: 45px;
            display: block; 
            overflow-y: auto;
        }
    }
    .item_btn {
        width: 100%;
        height: 42px;
        background-color: rgba(0, 0, 0, 0);
        padding-left: 3vh;
        padding-right: 3vh;
        line-height: 42px;
        margin-top: 3px;
        box-sizing: border-box;
        color: #666;
        .anticon {
            float: right !important;
            font-size: 18px;
            padding-top: 13px;
            box-sizing: border-box;
        }
    }
    .item_btn:hover {
        .item_btn();
        color: @bodyheaderbg;
    }
    .checked {
        background-color: @silderbtn;
    }
    .checked:hover {
        background-color: @silderbtn;
    }

    .main-box {
        width: 100vw;
        height: 92vh;
        .silder {
            width: 248px;
            // min-width: 208px;
            // max-width: 248px;
            background-color: @silderbg;
            float: left;
        }
        .content {
            width: auto;
            height: 92vh;
            margin-left: 248px;
            padding: 4vh 3vw 4vh 3vw;
            // background-color: aqua !important;
            position: relative;
            box-sizing: border-box;
            overflow-x: hidden;
            overflow-y: auto;
            .tab-btn {
                box-sizing: border-box;
                display: flex;
                margin-top: 20px;
                .ant-radio-group {
                    width: 100%;
                    // background-color: @silderbtn;
                    display: flex;
                    flex:1;
                    .ant-radio-button-wrapper {
                        height: 45px;
                        line-height: 45px;
                        background-color: @silderbg;
                    }
                    .ant-radio-button-wrapper:hover {
                        color: @bodyheaderbg !important;
                    }
                    .ant-radio-button-checked {
                        border: 1px @bodyheaderbg solid;
                        color: @bodyheaderbg;
                    }
                    .ant-radio-button-checked:hover {
                        color: @bodyheaderbg !important;
                    }
                    .ant-radio-button-wrapper-checked{
                        border: 1px @bodyheaderbg solid;
                        color: @bodyheaderbg;
                    }
                    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
                        background-color: @bodyheaderbg;
                        opacity: 0;
                    }
                }
                
            }
            .ant-tabs-nav-wrap {
                height: 0 !important;
                margin: 0 !important;
                padding: 0 !important;
            }
            .ant-tabs-nav-wrap::before {
                height: 0 !important;
                margin: 0 !important;
                padding: 0 !important;
            }
        }

        .hong {
            ._slider_hong {
                ._slider();
                ._slider_body {
                    height: 55vh;
                } 
                height: 66vh;
                padding: 2vh 1vh 2vh 1vh; 
                
            }
            .hongOtion {
                height: 8vh;
                padding: 2vh 1vh 1vh 1vh; 
                box-sizing: border-box;

                button {
                    // .ant-btn-default {

                    // }
                    color: @bodyheaderbg;
                    border: 1px @silderbg solid;
                }
                button:hover {
                    border: 1px @bodyheaderbg solid;
                }
            }
            .hongOtion-body {
                height: 58vh;
                padding: 2vh 1vh 2vh 1vh; 
                overflow-y: auto;
            }
        }
        .custom {
            height: 72vh;
            overflow: hidden;
            box-sizing: border-box;
            
            .keys {
                height: 35vh;
                margin-top: 3vh;
                .rest-btn {
                    height: 3vh;
                    text-align: right;
                    margin-right: 50px;
                    font-size: 16px;
                    margin-top: -16px;
                    color: @bodyheaderbg;
                }
                .key-board {
                    height: 32vh;
                    overflow-y: scroll;
                    box-sizing: content-box;
                }
                position: relative;
                :where(.css-dev-only-do-not-override-zg0ahe).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                    color: @bodyheaderbg;
                }
                .ant-tabs:hover {
                    color: @silderbtn !important;
                }
                .ant-tabs-tab-btn:hover {
                    color: @silderbtn !important;
                }
                .ant-tabs-tab-active:hover {
                    color: @silderbtn !important;
                }
                .ant-tabs-ink-bar {
                    background-color: @bodyheaderbg;
                }
            }
        }

        .other {
            height: 72vh;
            overflow: hidden;
            box-sizing: border-box;
            // background-color: #666;
            .other-body {
                height: 35vh;
                margin-top: 3vh;
                background-color: @silderbg;
                border-radius: 5px;
                .label {
                    height: 32px;
                    line-height: 32px;
                    font-size: 14px;
                    margin-right: 30px;
                }
            }
        }

        .linesetting {
            height: 72vh;
            overflow: hidden;
            box-sizing: border-box;
            // background-color: #666;
            .linesetting-body {
                height: 35vh;
                margin-top: 3vh;
                background-color: @silderbg;
                border-radius: 5px;
                padding: 2vh 3vh;
                .label {
                    height: 32px;
                    line-height: 32px;
                    font-size: 14px;
                    margin-right: 30px;
                    color: #666;
                }
            }
        }
       
    }
    .input-Btn {
        width: 60px;
        height: 60px;
        // text-align: center;
        margin: 3px;
        border-radius: 5px;
        color: @bodyheaderbg;
        background-color: @btnColor;
        border: 1px @bodybg solid;
        overflow: hidden;
    }
    .input-Btn:hover {
       .input-Btn();
        color: @bodyheaderbg !important;
        background-color: #fff !important;
        border: 1px @bodyheaderbg solid !important;
    }
    .input-Btn-checked {
        .input-Btn();
        color: @bodyheaderbg !important;
        background-color: #fff !important;
        border: 3px @bodyheaderbg solid !important;
    }

    .cell-Btn {
        width: 30px;
        height: 30px;
        // text-align: center;
        margin: 3px;
        border-radius: 5px;
        color: @bodyheaderbg;
        background-color: @btnColor;
        border: 1px @bodybg solid;
        .ant-input-number-group-addon {
            color:  @bodybg !important;
        }
    }
    .cell-Btn:hover {
        .cell-Btn();
        color: @bodyheaderbg !important;
        background-color: #fff !important;
        border: 1px @bodyheaderbg solid !important;
    }
    .cell-Btn-checked {
        .cell-Btn();
        color: @bodyheaderbg !important;
        background-color: #fff !important;
        border: 3px @btnColor solid !important;
    }

    .cell-Btn2 {
        width: 80px;
        height: 30px;
        // text-align: center;
        margin: 3px;
        border-radius: 5px;
        color: @bodyheaderbg;
        background-color: @btnColor;
        border: 1px @bodybg solid;
        overflow: hidden;
        .ant-input-number-group-addon {
            color:  @bodybg !important;
        }
    }
    .cell-Btn2:hover {
        .cell-Btn2();
        color: @bodyheaderbg !important;
        background-color: #fff !important;
        border: 1px @bodyheaderbg solid !important;
    }

    .cell-input {
        width: 120px;
        height: 30px;
        // text-align: center;
        margin: 3px;
        border-radius: 5px;
        color: @bodyheaderbg;
        background-color: @btnColor;
        border: 1px @bodybg solid;
        
    }
    // .cell-input:hover {
    //     width: 120px;
    //     height: 30px;
    //     // text-align: center;
    //     margin: 3px;
    //     border-radius: 5px;
    //     color: @bodyheaderbg !important;
    //     background-color: #fff !important;
    //     border: 1px @bodyheaderbg solid !important;
    // }

    .ant-slider {
        .ant-slider-track {
            background-color: @bodyheaderbg;
        }
        .ant-slider-handle:focus::after {
            box-shadow: 0 0 0 4px @bodyheaderbg !important;
        }
        .ant-slider-handle::after {
            box-shadow: 0 0 0 2px @bodyheaderbg !important;
        }
    }

    .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
        background: @bodyheaderbg;
    }
    .ant-switch.ant-switch-checked:not(.ant-switch-disabled) {
        background: @bodyheaderbg;
    }
    .ant-input-number-outlined:focus-within{
        border: 1px @bodyheaderbg solid !important;
    }
    .ant-input-number-outlined{
        border: 1px @btnColor solid !important;
    }
    .ant-input-number-group-wrapper-outlined .ant-input-number-group-addon {
        background: rgba(0, 0, 0, 0);
        border: 1px solid @btnColor;
    }
    .ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible) {
        color: @bodyheaderbg !important;
    }
    .ant-tabs .ant-tabs-tab-btn:not(:focus-visible) {
        color: @bodyheaderbg !important;
    }
    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: @bodyheaderbg !important;
    }
    .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
        background-color: @bodyheaderbg;
        outline: @silderbg;
    }


    /* 滚动条样式 */
    div::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    /* 滑块样式 */
    div::-webkit-scrollbar-thumb {
        background-color: @bodyheaderbg;
        border-radius: 10px;
    }
    /* 滚动条轨道样式 */
    div::-webkit-scrollbar-track {
        background-color: #f2f2f2;
        border-radius: 10px;
    }

    .board {
        width: 100%;
        height: 35vh;
        overflow-y: scroll;
        position: relative;
        text-align: center;
        overflow: hidden;
        .key-board-top {
            width: 100%;
            height: 35vh;
            display: block;
        }
        .key-board-body {
            background-color: @borderbgcolor;
            border-radius: 5px;
        }
    }

}

.black-theme {
    @bodyheaderbg: #000;
    @bodybg: #121212;
    @silderbtn: #333333;
    @silderbg: #212121;
    @btnColor: rgba(255,255,255,0.35);
    @borderbgcolor:#333333;

    width: 100vw;
    height: 100vh;
    background-color: @bodybg;
    position: relative;
    color: #999;
    span {
        color: #999;
    }
    .header {
        width: 100vw;
        height: 8vh;
        background-color: @bodyheaderbg;
        display: flex;
        // .logo {
        //     display: inline-flex;
        //     flex: 1;
        //     padding: 1vh 2vw ;
        //     box-sizing: border-box;
        //     img {
        //         width: auto;
        //         height: 7vh;
        //     }
        // }
        .logo {
            display: inline-flex;
            flex: 1;
            padding: 2vh 2vw 2vh 0;
            box-sizing: border-box;
            img {
                width: auto;
                height: 4vh;
                background-color: #0f0f0f;
            }
        }
        .menu {
            padding: 1vh 2vw ;
            display: inline-flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            line-height: 7vh;
            font-size: 4vh;
            .text {
                margin-left: 10px;
                font-size: 14px;
            }
            cursor: default;
        }
        .connect {
            color: #fff;
        }
        .disconnect {
            color: #666;
        }
        .setting {
            padding: 0vh 3vw ;
            display: inline-flex;
            flex: 1;
            .item {
                width: 100px;
                height: 30px;
                background-color: #fff;
                border-radius: 5px;
                text-align: center;
                line-height: 30px;
                margin-left: 16px;
                color: @bodyheaderbg;
            }
            align-items:center;
            justify-content:flex-end;
        }
    }

    ._slider {
        height: 92vh;
        // background-color: red;
        padding: 4.5vh 1vh 3vh 1vh;
        font-size: 14px;
        position: relative;
        box-sizing: border-box;
        ._slider_header_text {
            width: 120px;
            height: 3vh;
            // background-color: #FC5B6A;
            font-size: 14px;
            line-height: 30px;
            padding-left: 3vh;
            padding-right: 0;
            // background-color: black;
            display: inline-block;
            cursor: pointer;
            overflow: hidden;
        }
        ._slider_header_svg{
            width: 60px;
            height: 3vh;
            font-size: 20px;
            text-align: right;
            padding-right: 3vh;
            color: @bodyheaderbg;
            // background-color: black;
            display: inline-block;
            cursor: pointer;
            position: absolute;
            float: right;
        }
        ._slider_body {
            height: 78vh;
            // width: 100%;
            // background-color: black;
            margin-top: 0;
            display: block;
            overflow-y: auto;
        }
    }
    .item_btn {
        width: 100%;
        height: 42px;
        background-color: rgba(0, 0, 0, 0);
        padding-left: 3vh;
        padding-right: 3vh;
        line-height: 42px;
        margin-top: 3px;
        box-sizing: border-box;
        color: #666;
        .anticon {
            float: right !important;
            font-size: 18px;
            padding-top: 13px;
            box-sizing: border-box;
        }
    }
    .item_btn:hover {
        .item_btn();
        color: @bodyheaderbg;
    }
    .checked {
        background-color: @silderbtn;
    }
    .checked:hover {
        background-color: @silderbtn;
    }

    .main-box {
        width: 100vw;
        height: 92vh;
        .silder {
            width: 248px;
            // min-width: 208px;
            // max-width: 248px;
            background-color: @silderbg;
            float: left;
        }
        .content {
            width: auto;
            height: 92vh;
            margin-left: 248px;
            padding: 4vh 3vw 4vh 3vw;
            // background-color: aqua !important;
            position: relative;
            box-sizing: border-box;
            overflow-x: hidden;
            overflow-y: hidden;
            .tab-btn {
                box-sizing: border-box;
                display: flex;
                margin-top: 20px;
                .ant-radio-group {
                    width: 100%;
                    // background-color: @silderbtn;
                    display: flex;
                    flex:1;
                    .ant-radio-button-wrapper {
                        height: 45px;
                        line-height: 45px;
                        background-color: @silderbg;
                    }
                    .ant-radio-button-wrapper:hover {
                        color: @bodyheaderbg !important;
                    }
                    .ant-radio-button-checked {
                        border: 1px @bodyheaderbg solid;
                        color: @bodyheaderbg;
                    }
                    .ant-radio-button-checked:hover {
                        color: @bodyheaderbg !important;
                    }
                    .ant-radio-button-wrapper-checked{
                        border: 1px @bodyheaderbg solid;
                        color: @bodyheaderbg;
                    }
                    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
                        background-color: @bodyheaderbg;
                        opacity: 0;
                    }
                }
                
            }
            .ant-tabs-nav-wrap {
                height: 0 !important;
                margin: 0 !important;
                padding: 0 !important;
            }
            .ant-tabs-nav-wrap::before {
                height: 0 !important;
                margin: 0 !important;
                padding: 0 !important;
            }
        }

        .hong {
            ._slider_hong {
                ._slider();
                ._slider_body {
                    height: 55vh;
                } 
                height: 66vh;
                padding: 2vh 1vh 2vh 1vh;
            }
            .hongOtion {
                height: 8vh;
                padding: 2vh 1vh 1vh 1vh; 
                box-sizing: border-box;

                button {
                    // .ant-btn-default {

                    // }
                    color: @bodyheaderbg;
                    border: 1px @silderbg solid;
                }
                button:hover {
                    border: 1px @bodyheaderbg solid;
                }
            }
            .hongOtion-body {
                height: 58vh;
                padding: 2vh 1vh 2vh 1vh; 
                overflow-y: auto;
            }
        }
        .custom {
            height: 72vh;
            overflow: hidden;
            box-sizing: border-box;
            
            .keys {
                height: 35vh;
                margin-top: 3vh;
                .rest-btn {
                    height: 3vh;
                    text-align: right;
                    margin-right: 50px;
                    font-size: 16px;
                    margin-top: -16px;
                    color: @bodyheaderbg;
                }
                .key-board {
                    height: 32vh;
                    overflow-y: auto;
                    box-sizing: content-box;
                }
                position: relative;
                :where(.css-dev-only-do-not-override-zg0ahe).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                    color: @bodyheaderbg;
                }
                .ant-tabs:hover {
                    color: @silderbtn !important;
                }
                .ant-tabs-tab-btn:hover {
                    color: @silderbtn !important;
                }
                .ant-tabs-tab-active:hover {
                    color: @silderbtn !important;
                }
                .ant-tabs-ink-bar {
                    background-color: @bodyheaderbg;
                }
            }
        }

        .other {
            height: 72vh;
            overflow: hidden;
            box-sizing: border-box;
            // background-color: #666;
            .other-body {
                height: 35vh;
                margin-top: 3vh;
                background-color: @silderbg;
                border-radius: 5px;
                .label {
                    height: 32px;
                    line-height: 32px;
                    font-size: 14px;
                    margin-right: 30px;
                }
            }
        }

        .linesetting {
            height: 72vh;
            overflow: hidden;
            box-sizing: border-box;
            // background-color: #666;
            .linesetting-body {
                height: 35vh;
                margin-top: 3vh;
                background-color: @silderbg;
                border-radius: 5px;
                padding: 2vh 3vh;
                .label {
                    height: 32px;
                    line-height: 32px;
                    font-size: 14px;
                    margin-right: 30px;
                    color: #666;
                }
            }
        }
       
    }
    .input-Btn {
        width: 60px;
        height: 60px;
        // text-align: center;
        margin: 3px;
        border-radius: 5px;
        color: @bodyheaderbg;
        background-color: @btnColor;
        border: 1px @bodybg solid;
        overflow: hidden;
    }
    .input-Btn:hover {
       .input-Btn();
        color: @bodyheaderbg !important;
        background-color: #fff !important;
        border: 1px @bodyheaderbg solid !important;
    }
    .input-Btn-checked {
        .input-Btn();
        color: @bodyheaderbg !important;
        background-color: #fff !important;
        border: 3px @bodyheaderbg solid !important;
    }

    .cell-Btn {
        width: 30px;
        height: 30px;
        // text-align: center;
        margin: 3px;
        border-radius: 5px;
        color: @bodyheaderbg;
        background-color: @btnColor;
        border: 1px @bodybg solid;
        .ant-input-number-group-addon {
            color:  @bodybg !important;
        }
    }
    .cell-Btn:hover {
        .cell-Btn();
        color: @bodyheaderbg !important;
        background-color: #fff !important;
        border: 1px @bodyheaderbg solid !important;
    }
    .cell-Btn-checked {
        .cell-Btn();
        color: @bodyheaderbg !important;
        background-color: #fff !important;
        border: 3px @btnColor solid !important;
    }

    .cell-Btn2 {
        width: 80px;
        height: 30px;
        // text-align: center;
        margin: 3px;
        border-radius: 5px;
        color: @bodyheaderbg;
        background-color: @btnColor;
        border: 1px @bodybg solid;
        overflow: hidden;
        .ant-input-number-group-addon {
            color:  @bodybg !important;
        }
    }
    .cell-Btn2:hover {
        .cell-Btn2();
        color: @bodyheaderbg !important;
        background-color: #fff !important;
        border: 1px @bodyheaderbg solid !important;
    }

    .cell-input {
        width: 120px;
        height: 30px;
        // text-align: center;
        margin: 3px;
        border-radius: 5px;
        color: @bodyheaderbg;
        background-color: @btnColor;
        border: 1px @bodybg solid;
        
    }
    
    // .cell-input:hover {
    //     width: 120px;
    //     height: 30px;
    //     // text-align: center;
    //     margin: 3px;
    //     border-radius: 5px;
    //     color: @bodyheaderbg !important;
    //     background-color: #fff !important;
    //     border: 1px @bodyheaderbg solid !important;
    // }

    .ant-slider {
        .ant-slider-track {
            background-color: @bodyheaderbg;
        }
        .ant-slider-handle:focus::after {
            box-shadow: 0 0 0 4px @bodyheaderbg !important;
        }
        .ant-slider-handle::after {
            box-shadow: 0 0 0 2px @bodyheaderbg !important;
        }
    }

    .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
        background: #fff;
    }
    .ant-switch.ant-switch-checked:not(.ant-switch-disabled) {
        background: #fff;
    }
    .ant-input-number-outlined:focus-within{
        border: 1px @bodyheaderbg solid !important;
    }
    .ant-input-number-outlined{
        border: 1px @btnColor solid !important;
    }
    .ant-input-number-group-wrapper-outlined .ant-input-number-group-addon {
        background: rgba(0, 0, 0, 0);
        border: 1px solid @btnColor;
    }
    .ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible) {
        color: #f2f2f2 !important;
    }
    .ant-tabs .ant-tabs-tab-btn:not(:focus-visible) {
        color: #f2f2f2 !important;
    }
    // .ant-tabs-tab-btn {
    //     color: #f2f2f2 !important;
    // }
    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #f2f2f2 !important;
    }
    .ant-card-body {
        background-color: @silderbg;
    }
    .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
        background-color: @bodyheaderbg;
        border: 1px #fff solid;
        outline: #fff;
    }

    /* 滚动条样式 */
    div::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    /* 滑块样式 */
    div::-webkit-scrollbar-thumb {
        background-color: #999;
        border-radius: 10px;
    }
    /* 滚动条轨道样式 */
    div::-webkit-scrollbar-track {
        background-color: #f2f2f2;
        border-radius: 10px;
    }

    .board {
        width: 100%;
        height: 35vh;
        overflow-y: scroll;
        position: relative;
        text-align: center;
        overflow: hidden;
        .key-board-top {
            width: 100%;
            height: 35vh;
            display: block;
        }
        .key-board-body {
            background-color: #d0d0d0;
            border-radius: 5px;
        }
    }
    .label {
        color: #fff;
    }
}

.line-theme {
    @bodyheaderbg: #60A0E7;
    @bodybg: #D3D3D3;
    @silderbtn: #FFFFFF;
    @silderbg: #FFFFFF;
    @btnColor: #F4F6F8;
    @borderbgcolor:#808080;
    width: 100vw;
    height: 100vh;
    background-color: @bodybg;
    position: relative;
    .header {
        width: 100vw;
        height: 8vh;
        background-color: @bodyheaderbg;
        display: flex;
        // .logo {
        //     display: inline-flex;
        //     flex: 1;
        //     padding: 1vh 2vw ;
        //     box-sizing: border-box;
        //     img {
        //         width: auto;
        //         height: 7vh;
        //     }
        // }
        .logo {
            display: inline-flex;
            flex: 1;
            padding: 2vh 2vw 2vh 0;
            box-sizing: border-box;
            img {
                width: auto;
                height: 4vh;
            }
        }
        .menu {
            padding: 1vh 2vw ;
            display: inline-flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            line-height: 7vh;
            font-size: 4vh;
            .text {
                margin-left: 10px;
                font-size: 14px;
            }
            cursor: default;
        }
        .connect {
            color: #fff;
        }
        .disconnect {
            color: #666;
        }
        .setting {
            padding: 0vh 3vw ;
            display: inline-flex;
            flex: 1;
            .item {
                width: 100px;
                height: 30px;
                background-color: #fff;
                border-radius: 5px;
                text-align: center;
                line-height: 30px;
                margin-left: 16px;
                color: @bodyheaderbg;
            }
            align-items:center;
            justify-content:flex-end;
        }
    }

    ._slider {
        height: 92vh;
        // background-color: red;
        padding: 4.5vh 1vh 3vh 1vh;
        font-size: 14px;
        position: relative;
        box-sizing: border-box;
        ._slider_header_text {
            width: 120px;
            height: 3vh;
            // background-color: #FC5B6A;
            font-size: 14px;
            line-height: 30px;
            padding-left: 3vh;
            padding-right: 0;
            // background-color: black;
            display: inline-block;
            cursor: pointer;
            overflow: hidden;
        }
        ._slider_header_svg{
            height: 3vh;
            font-size: 20px;
            text-align: right;
            padding-right: 3vh;
            color: @bodyheaderbg;
            // background-color: black;
            display: inline-block;
            cursor: pointer;
            position: absolute;
            float: right;
        }
        ._slider_body {
            height: 78vh;
            // width: 100%;
            // background-color: black;
            margin-top: 0;
            display: block;
            overflow-y: auto;
        }
    }
    .item_btn {
        width: 100%;
        height: 42px;
        background-color: rgba(0, 0, 0, 0);
        padding-left: 3vh;
        padding-right: 3vh;
        line-height: 42px;
        margin-top: 3px;
        box-sizing: border-box;
        color: #666;
        .anticon {
            float: right !important;
            font-size: 18px;
            padding-top: 13px;
            box-sizing: border-box;
        }
    }
    .item_btn:hover {
        .item_btn();
        color: @bodyheaderbg;
    }
    .checked {
        background-color: #609fe78f;
    }
    .checked:hover {
        background-color: @silderbtn;
    }

    .main-box {
        width: 100vw;
        height: 92vh;
        .silder {
            width: 248px;
            // min-width: 208px;
            // max-width: 248px;
            background-color: @silderbg;
            float: left;
        }
        .content {
            width: auto;
            height: 92vh;
            margin-left: 248px;
            padding: 4vh 3vw 4vh 3vw;
            // background-color: aqua !important;
            position: relative;
            box-sizing: border-box;
            overflow-x: hidden;
            overflow-y: auto;
            .tab-btn {
                box-sizing: border-box;
                display: flex;
                margin-top: 20px;
                .ant-radio-group {
                    width: 100%;
                    // background-color: @silderbtn;
                    display: flex;
                    flex:1;
                    .ant-radio-button-wrapper {
                        height: 45px;
                        line-height: 45px;
                        background-color: @silderbg;
                    }
                    .ant-radio-button-wrapper:hover {
                        color: @bodyheaderbg !important;
                    }
                    .ant-radio-button-checked {
                        border: 1px @bodyheaderbg solid;
                        color: @bodyheaderbg;
                    }
                    .ant-radio-button-checked:hover {
                        color: @bodyheaderbg !important;
                    }
                    .ant-radio-button-wrapper-checked{
                        border: 1px @bodyheaderbg solid;
                        color: @bodyheaderbg;
                    }
                    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
                        background-color: @bodyheaderbg;
                        opacity: 0;
                    }
                }
                
            }
            .ant-tabs-nav-wrap {
                height: 0 !important;
                margin: 0 !important;
                padding: 0 !important;
            }
            .ant-tabs-nav-wrap::before {
                height: 0 !important;
                margin: 0 !important;
                padding: 0 !important;
            }
        }

        .hong {
            ._slider_hong {
                ._slider();
                ._slider_body {
                    height: 55vh;
                } 
                height: 66vh;
                padding: 2vh 1vh 2vh 1vh; 
            }
            .hongOtion {
                height: 8vh;
                padding: 2vh 1vh 1vh 1vh; 
                box-sizing: border-box;

                button {
                    // .ant-btn-default {

                    // }
                    color: @bodyheaderbg;
                    border: 1px @silderbg solid;
                }
                button:hover {
                    border: 1px @bodyheaderbg solid;
                }
            }
            .hongOtion-body {
                height: 58vh;
                padding: 2vh 1vh 2vh 1vh; 
                overflow-y: auto;
            }
        }
        .custom {
            height: 72vh;
            overflow: hidden;
            box-sizing: border-box;
            
            .keys {
                height: 35vh;
                margin-top: 3vh;
                .rest-btn {
                    height: 3vh;
                    text-align: right;
                    margin-right: 50px;
                    font-size: 16px;
                    margin-top: -16px;
                    color: @bodyheaderbg;
                }
                .key-board {
                    height: 32vh;
                    overflow-y: scroll;
                    box-sizing: content-box;
                }
                position: relative;
                :where(.css-dev-only-do-not-override-zg0ahe).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                    color: @bodyheaderbg;
                }
                .ant-tabs:hover {
                    color: @silderbtn !important;
                }
                .ant-tabs-tab-btn:hover {
                    color: @silderbtn !important;
                }
                .ant-tabs-tab-active:hover {
                    color: @silderbtn !important;
                }
                .ant-tabs-ink-bar {
                    background-color: @bodyheaderbg;
                }
            }
        }

        .other {
            height: 72vh;
            overflow: hidden;
            box-sizing: border-box;
            // background-color: #666;
            .other-body {
                height: 35vh;
                margin-top: 3vh;
                background-color: @silderbg;
                border-radius: 5px;
                .label {
                    height: 32px;
                    line-height: 32px;
                    font-size: 14px;
                    margin-right: 30px;
                }
            }
        }

        .linesetting {
            height: 72vh;
            overflow: hidden;
            box-sizing: border-box;
            // background-color: #666;
            .linesetting-body {
                height: 35vh;
                margin-top: 3vh;
                background-color: @silderbg;
                border-radius: 5px;
                padding: 2vh 3vh;
                .label {
                    height: 32px;
                    line-height: 32px;
                    font-size: 14px;
                    margin-right: 30px;
                    color: #666;
                }
            }
        }
       
    }
    .input-Btn {
        width: 60px;
        height: 60px;
        // text-align: center;
        margin: 3px;
        border-radius: 5px;
        color: @bodyheaderbg;
        background-color: @btnColor;
        border: 1px @bodybg solid;
        overflow: hidden;
    }
    .input-Btn:hover {
       .input-Btn();
        color: @bodyheaderbg !important;
        background-color: #fff !important;
        border: 1px @bodyheaderbg solid !important;
    }
    .input-Btn-checked {
        .input-Btn();
        color: @bodyheaderbg !important;
        background-color: #fff !important;
        border: 3px @bodyheaderbg solid !important;
    }
    .cell-Btn {
        width: 30px;
        height: 30px;
        // text-align: center;
        margin: 3px;
        border-radius: 5px;
        color: @bodyheaderbg;
        background-color: @btnColor;
        border: 1px @bodybg solid;
        .ant-input-number-group-addon {
            color:  @bodybg !important;
        }
    }
    .cell-Btn:hover {
        .cell-Btn();
        color: @bodyheaderbg !important;
        background-color: #fff !important;
        border: 1px @bodyheaderbg solid !important;
    }

    .cell-Btn-checked {
        .cell-Btn();
        color: @bodyheaderbg !important;
        background-color: #fff !important;
        border: 3px @btnColor solid !important;
    }

    .cell-Btn2 {
        width: 80px;
        height: 30px;
        // text-align: center;
        margin: 3px;
        border-radius: 5px;
        color: @bodyheaderbg;
        background-color: @btnColor;
        border: 1px @bodybg solid;
        overflow: hidden;
        .ant-input-number-group-addon {
            color:  @bodybg !important;
        }
    }
    .cell-Btn2:hover {
        .cell-Btn2();
        color: @bodyheaderbg !important;
        background-color: #fff !important;
        border: 1px @bodyheaderbg solid !important;
    }

    .cell-input {
        width: 120px;
        height: 30px;
        // text-align: center;
        margin: 3px;
        border-radius: 5px;
        color: @bodyheaderbg;
        background-color: @btnColor;
        border: 1px @bodybg solid;
        
    }
    // .cell-input:hover {
    //     width: 120px;
    //     height: 30px;
    //     // text-align: center;
    //     margin: 3px;
    //     border-radius: 5px;
    //     color: @bodyheaderbg !important;
    //     background-color: #fff !important;
    //     border: 1px @bodyheaderbg solid !important;
    // }

    .ant-slider {
        .ant-slider-track {
            background-color: @bodyheaderbg;
        }
        .ant-slider-handle:focus::after {
            box-shadow: 0 0 0 4px @bodyheaderbg !important;
        }
        .ant-slider-handle::after {
            box-shadow: 0 0 0 2px @bodyheaderbg !important;
        }
    }

    .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
        background: @bodyheaderbg;
    }
    .ant-switch.ant-switch-checked:not(.ant-switch-disabled) {
        background: @bodyheaderbg;
    }
    .ant-input-number-outlined:focus-within{
        border: 1px @bodyheaderbg solid !important;
    }
    .ant-input-number-outlined{
        border: 1px @btnColor solid !important;
    }
    .ant-input-number-group-wrapper-outlined .ant-input-number-group-addon {
        background: rgba(0, 0, 0, 0);
        border: 1px solid @btnColor;
    }
    .ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible) {
        color: @bodyheaderbg !important;
    }
    .ant-tabs .ant-tabs-tab-btn:not(:focus-visible) {
        color: @bodyheaderbg !important;
    }
    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: @bodyheaderbg !important;
    }

    /* 滚动条样式 */
    div::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    /* 滑块样式 */
    div::-webkit-scrollbar-thumb {
        background-color: @bodyheaderbg;
        border-radius: 10px;
    }
    /* 滚动条轨道样式 */
    div::-webkit-scrollbar-track {
        background-color: #f2f2f2;
        border-radius: 10px;
    }

    .board {
        width: 100%;
        height: 35vh;
        overflow-y: scroll;
        position: relative;
        text-align: center;
        overflow: hidden;
        .key-board-top {
            width: 100%;
            height: 35vh;
            display: block;
        }
        .key-board-body {
            background-color: #e0e0e0;
            border-radius: 5px;
        }
    }
}

.text{
    user-select: none;
}

.connectTip {
    width: 100%;
    height: 100px;
    line-height: 100px;
    color: #666;
}

.w-color-wheel-pointer {
    display: none;
}
.poor {
    margin-left: 10px;
    width: 30px;
    height: 16px;
    background-color: #e0e0e0;
    position: relative;
    border-radius: 2px;
    .tag {
        width: 5px;
        height: 8px;
        position: absolute;
        right: -6px;
        top: 4px;
        background-color: #e0e0e0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }
    .show {
        width: 26px;
        height: 12px;
        position: absolute;
        left: 2px;
        top: 2px;
        background-color: #84D232;
        border-radius: 2px;
    }
    .poortextbox {
        width: 45px;
        height: 16px;
        line-height: 16px;
        position: absolute;
        right: -55px;    
        top: 0;
        font-size: 14px;
        // background-color: #000;
    }
    .poor-show {
        font-size: 12px;
        position: absolute;
        top: 3px;
        left: 10px;
        color: #fff;
    }
    
}

@primary-color: #1DA57A;